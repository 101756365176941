import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip"

import {
    faYoutube,
    faSpotify,
    faFacebook,
    faTwitter,
    faInstagram,
    faSoundcloud
  } from "@fortawesome/free-brands-svg-icons";

import React from "react";

export default function SocialFollow() {
  return (
        <div className="social-container">
          <ReactTooltip />
     Follow us on social media for all the latest church news and information: &nbsp;
     <div>
      <a href="https://www.youtube.com/channel/UCZ-I6sZo2plhiUM1kb7ts-Q"
        data-tip="Barvas Free Church Youtube channel"
        className="youtube social">
        <FontAwesomeIcon icon={faYoutube} size="2x" />
      </a> 
      <a href="https://en-gb.facebook.com/BarvasFreeChurch/"
        data-tip="Barvas Free Church Facebook page"
        className="facebook social">
        <FontAwesomeIcon icon={faFacebook} size="2x" /> 
       </a> 
       <a href="https://open.spotify.com/show/0qAycKtGQOyG6wDpyfdzfH"
        data-tip="Barvas Free Church Spotify account"
        className="spotify social">
        <FontAwesomeIcon icon={faSpotify} size="2x" /> 
       </a> 
       </div>
    </div>
  );
}